@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    transition: 0.1s ease-out;
}

.walletconnect-modal__mobile__toggle a {
    color: #000000;
}

body {
    background-color: #112455;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}
h1, h2, h3, h4, h5, h6, p {
    margin: 0!important;
}
a {
    text-decoration: none;
    color: #17E7D6;
}
a:hover {
    color: #17E7D6;
}
.desktop {display: none}
.mobile {display: block}
.p-4 {padding: 16px;}
.pt-2 {padding-top: 8px!important;}
.pt-4 {padding-top: 16px!important;}
.pt-6 {padding-top: 24px!important;}
.pt-10 {padding-top: 40px!important;}
.pt-21 {padding-top: 84px!important;}
.pb-2 {padding-bottom: 8px!important;}
.pb-3 {padding-bottom: 12px!important;}
.pb-6 {padding-bottom: 24px!important;}
.pb-15 {padding-bottom: 60px!important;}
.pl-2 {padding-left: 8px!important;}
.pl-3 {padding-left: 12px!important;}
.pr-2 {padding-right: 8px!important;}
.pr-4 {padding-right: 16px!important;}
.px-3 {padding-right: 12px!important; padding-left: 12px!important;}
.px-4 {padding-right: 16px!important; padding-left: 16px!important;}
.px-5 {padding-right: 20px!important; padding-left: 20px!important;}
.px-7 {padding-left: 28px!important; padding-right: 28px!important;}
.px-15 {padding-left: 60px!important; padding-right: 60px!important;}
.py-15 {padding-top: 60px!important; padding-bottom: 60px!important;}
.py-40 {padding-top: 60px!important; padding-bottom: 160px!important;}
.py-4 {padding-top: 16px!important; padding-bottom: 16px!important;}
.ml-2 {margin-left: 8px!important;}
.ml-4 {margin-left: 16px!important;}
.ml-6 {margin-left: 24px!important;}
.ml-10 {margin-left: 40px!important;}
.ml-12 {margin-left: 50px!important;}
.mr-2 {margin-right: 8px!important;}
.mr-3 {margin-right: 12px!important;}
.mr-4 {margin-right: 16px!important;}
.mb-3 {margin-bottom: 12px!important;}
.mb-2 {margin-bottom: 8px!important;}
.mb-4 {margin-bottom: 16px!important;}
.mb-5 {margin-bottom: 20px!important;}
.mb-6 {margin-bottom: 24px!important;}
.mb-7-5 {margin-bottom: 30px!important;}
.mb-9 {margin-bottom: 36px!important;}
.mb-10 {margin-bottom: 40px!important;}
.mb-11 {margin-bottom: 44px!important;}
.mb-12 {margin-bottom: 48px!important;}
.mb-16 {margin-bottom: 64px!important;}
.mb-19 {margin-bottom: 76px!important;}
.mb-20 {margin-bottom: 80px!important;}
.my-8 {margin-top: 32px!important; margin-bottom: 32px!important;}
.mt-4 {margin-top: 24px!important;}
.mt-6 {margin-top: 24px!important;}
.mt-143px {margin-top: 143px!important;}
.mt-11 {margin-top: 44px!important;}
.m-2 {margin: 8px!important;}
.w-full {width: 100%!important}
.cursor-pointer {cursor: pointer;}
.text-danger {color: red}
.text-center {text-align: center;}
.text-12 {font-size: 12px;}
.text-13 {font-size: 13px;}
.text-14 {font-size: 14px;}
.text-16 {font-size: 16px;}
.text-18 {font-size: 18px;}
.text-20 {font-size: 20px;}
.text-right {
    text-align: right;
}
.text-bold {
    font-weight: 600;
}
.text-semibold {
    font-weight: 500;
}
.border {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.flex {
    display: flex;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.flex-col {
    flex-direction: column;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
ul li {
    display: inline;
    margin-right: 8px;
}
.root {
    position: relative;
}
.header {
    position: unset;
    padding: 33px 19px;
}
.logo {
    width: 99px;
}
.bg-polygon {
    display: none;
}
.main-wrapper {
    max-width: 1306px;
    width: 100%;
    min-height: 100vh;
    margin: 60px auto;
    /* z-index: 8; */
}
.pot-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.pot-container h6 {
    color: #112455;
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
}
.pot-logo {
    width: 100%;
}
.titan-img {
    width: 45px;
    height: 50px;
}
.btn-round {
    height: 40px;
    width: 180px;
    /* padding: 0 28px; */
    background-color: #17E7D6;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    z-index: 100;
}
.text-btn {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #112455;
}
.heading-box {
    padding: 16px 23px 20px 23px;
}
.content {
    background-color: white;
    border-radius: 40px;
    width: 100%;
    /* min-height: 100vh; */
    margin-top: 0;
}
.detail-text p {
    margin-bottom: 10px !important;
    line-height: 24px;
    color: #F1F1F1;
}
.sm-label {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 13px;
}
.heading {
    font-size: 22px;
    font-weight: 600 !important;
    color: white;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 20px!important;
}
.sub-heading {
    font-size: 16px;
    color: white;
    line-height: 19px;
    font-weight: 400;
}
.coin-logo {
    width: 30px;
    height: 30px;
}
.chain-container {
    padding: 16px 7px 0 7px;
}
.chain-wrapper {
    padding: 19px 12px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    margin-bottom: 25px;
    font-family: 'Montserrat', sans-serif;
}
.main-info {
    display: flex;
    align-items: center;
}
.main-part {
    flex: 1;
}
.arrow-part {
    width: 32px;
}
.title-wrapper {
    margin-bottom: 14px;
}
.title {
    font-size: 18px;
    line-height: 22px;
    color: #181F2B;
    font-weight: 700;
}
.coin-label {
    color: #cacfdb;
    font-size: 12px;
    line-height: 15px;
}
.coin-value {
    color: #181F2B;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
}
.arrow-icon {
    color: #17E7D6;
}
.logo-wrapper-1 {
    z-index: 10;
}
.logo-wrapper-2 {
    margin-left: -10px!important;
}
.grid-box {
    display: block;
}

.detail-info {
    padding-top: 25px;
}
.detail-box {
    padding: 16px 8px;
}
.btn-count {
    width: 41px;
    height: 41px;
    background-color: #17E7D6;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #112455
}
.info {
    display: flex;
    align-items: center;
}
.info-text {
    color: #112455;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
}
.info img {
    width: 16px;
    height: 16px;
}
.text-small {
    color: #cacfdb;
    font-size: 11px;
    line-height: 13px;
    font-weight: 600;
}
.btn-green {
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #112455;
    background-color: #17E7D6;
    border: none;
    border-radius: 20px;
    padding: 0px 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.btn-label {
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #112455;
    background-color: #F9FAFB;
    border: none;
    border-radius: 20px;
    padding: 0px 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.btn-normal {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #112455;
    background-color: #17E7D6;
    border: none;
    border-radius: 12px;
    padding: 10px 38px;
    cursor: pointer;
}
.btn-round-lg {
    background-color: #17E7D6;
    font-size: 15px;
    font-weight: 500;
    color: #112455;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #17E7D6;
    border-radius: 50px;
    width: 160px;
    height: 52px;
    cursor: pointer;
}
.btn-border-lg {
    background-color: #112455;
    font-size: 15px;
    font-weight: 500;
    color: #17E7D6;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #17E7D6;
    border-radius: 50px;
    width: 160px;
    height: 52px;
    cursor: pointer;
}
.text-normal {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
}
.text-gray {
    color: #7A8291;
    font-weight: 500;
}
.detail-icon {
    width: 12px;
    height: 12px;
}
.detail-wrapper {
    padding-right: 0;
}
.detail-last {
    padding-left: 0;
}
.blank-block {
    padding-bottom: 0;
}
.btn-wrapper {
    padding-right: 4px;
}

/* Footer */
.footer {
    padding: 35px 0;
}
.footer a {
    color: white;
}
.footer a:hover {
    color: #17E7D6;
}
.social-item {
    margin-right: 12px;
}
.mail-icon {
    width: 20px;
    height: 20px;
    background-image: url(../images/mail.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 4px;
}
.mail-icon:hover {
    background-image: url(../images/mail-hover.svg);
}
.logo-wrapper {
    padding: 18px;
}
.logo-box {
    width: 103px;
    padding-bottom: 22px;
    border-bottom: 2px solid #17E7D6;
}
.social-wrapper {
    padding: 18px;
}
.social-wrapper h6 {
    color: #a0a7bb;
    font-size: 16px;
    font-weight: 400;
}
.f-menu-wrapper {
    display: flex;
    justify-content: flex-start;
}
.f-menu-box {
    display: flex;
    flex-wrap: wrap;
}
.f-menu-item {
    font-size: 14px;
    padding: 14px;
    margin: 0;
}
.footer-logo {
    padding: 0 29px;
}
.footer-logo img {
    width: 168px;
}
.footer-logo h3 {
    margin-bottom: 12px !important;
    font-size: 20px;
    font-weight: 600;
}
/* Stake Popup */
.bg-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(17, 36, 85, 0.5);
}
.popup-wrapper {
    padding: 0 12px;
    width: 100%;
    max-width: 653px;
}
.popup-box {
    background-color: white;
    border-radius: 21px;
}
.card-header {
    border-bottom: 1px solid #E7E7E7;
    padding: 10px 0 9px 27px;
}
.card-header h4 {
    font-size: 18px;
    line-height: 22px;
    color: #112455;
    font-weight: 400;
}
.card-body {
    padding: 25px 20px 28px;
}
.stake-box {
    padding: 17px 15px 9px 15px;
    border: 1px solid #17E7D6;
    border-radius: 14px;
}
.stake-label {
    font-size: 16px;
    line-height: 19px;
    color: #9aa6cf;
    font-weight: 400;
}
.stake-value {
    font-size: 16px;
    line-height: 19px;
    color: #112455;
    font-weight: 400;
    flex: 1;
}
.button-group {
    padding-top: 38px;
}
.btn-stake {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    padding: 11px 25px;
    border-radius: 8px;
    cursor: pointer;
}
.btn-confirm {
    color: #112455;
    background-color: #17E7D6;
    border: none;
    margin: 0 4px;
}
.btn-cancel {
    color: #9aa6cf;
    border: 1px solid #9aa6cf;
    margin-right: 10px;
    background-color: white;
}
.btn-close {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #112455;
    background-color: white;
    border: 1px solid #112455;
    border-radius: 12px;
    padding: 10px 38px;
    width: 230px;
    cursor: pointer;
    margin-bottom: 24px;
}
.card-footer {
    padding-bottom: 30px;
}
.card-footer a {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    color :#17E7D6;
}
.card-footer p {
    font-size: 14px;
}
.badge {
    background-color: #17E7D6;
    border-radius: 20px;
    padding: 5px 12px;
    color: #112455;
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
}
.wallet-box {
    background-color: #212329;
    border: 1px solid #31343E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-radius: 12px;
    padding: 0 15px;
    margin-top: 16px;
    cursor: pointer;
}
.wallet-box:hover {
    border: 1px solid #17E7D6;
}
.result-text {
    font-size: 30px;
    font-weight: 600;
    color: #9AA6CF;
    padding-top: 16px;
}
.result-text span {
    color: #112455;
}
/* NFT section */
.nft-section {
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;
    padding: 198px 0;
}
.poseidon-nft-section {
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;
    padding: 0 0 198px;
}
.nft-container {
    padding: 0 24px;
}
.nft-container h3 {
    color: white;
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
}
.nft-container h6 {
    color: #9AA6CF;
    font-size: 21px;
    line-height: 25px;
    font-weight: normal;
    padding: 22px 0 39px;
}
.nft-card {
    padding: 16px;
    border-radius: 14px;
    background-color: white;
    cursor: pointer;
}
.nft-img {
    width :100%;
    height: 0;
    padding-top: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.nft-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}
.nft-icon img {
    height: 138%;
    object-fit: cover;
}
.nft-icon + p {
    font-size: 14px;
}
.nft-footer {
    padding-top: 23px;
}
.nft-footer h4 {
    color: #112455;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 8px;
}
.nft-info div img {
    width: 21px;
    height: 21px;
    margin-right: 5px;
}
.liq-label {
    color: #17E7D6;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    margin-right: 15px;
}
.soldout h4 {
    font-size: 21px;
    font-weight: bold;
    line-height: 26px;
    color: #112455;
}
.soldout p {
    font-size: 15px;
    line-height: 18px;
    color: #112455;
}
.soldout-desc {
    background-color: #17E7D6;
    border-radius: 8px;
    padding: 12px 20px;
    margin-top: 30px;
}
.soldout-desc img {
    width: 34px;
    margin-right: 18px;
}
.soldout-desc p {
    flex: 1;
}
.email-input {
    padding-top: 30px;
}
.email-input input {
    flex: 1;
    height: 48px;
    border-width: 0 0 1px 0; 
    border-bottom: 1px solid #112455;
    outline: none;
    font-size: 15px;
}
.email-input button {
    width: 147px;
    height: 51px;
    background-color: #112455;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 8px;
    margin-left: 16px;
    cursor: pointer;
}
.deposit-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.deposit-success img {
    width: 246px;
    height: 196px;
}
.deposit-success p {
    font-size: 16px;
    line-height: 18px;
}
.deposit-success span {
    font-weight: bold;
}
.probox {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #112455;
    color: #17E7D6;
}
.done {
    width: 268px;
    height: 51px;
    background-color: #17E7D6;
    color: #112455;
    border-radius: 12px;
    margin-top: 43px;
    font-size: 16px;
    cursor: pointer;
}
.token-2-label {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
}
.busd-color {
    color: #EABC4E;
}
.usdc-color {
    color: #2775ca;
}
.nft-info p {
    color: #7A8291;
    font-size: 12px;
    line-height: 15px;
    padding-top: 15px;
}
.nft-apy {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.nft-badge {
    background-color: #112455;
    color: #17E7D6;
    padding: 9px 12px;
    font-size: 12px;
    border-radius: 8px;
    border: none;
}
.notif-box {
    text-align: center;
    padding-top: 32px;
    font-size: 16px;
    font-weight: bold;
}
.error {
    color: red;
}
.success {
    color: #17E7D6;
}

/* Detail page */
.detail-header {
    padding: 33px 19px;
}
.close-icon {
    width: 25px;
    height: 25px;
    color: white;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.detail-container {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 120px;
    color: white;
}
.detail-container a {
    color: #17E7D6;
}
.nft-detail h1 {
    font-size: 30px;
    line-height: 50px;
    font-weight: 600;
}
.nft-detail h3 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #cacfdb;
    margin-bottom: 10px !important;
}
.nft-detail h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 7px !important;
}
.nft-detail h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0px !important;
}
.nft-detail h5 {
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    color: #a0a8bd;
}
.nft-detail h6 {
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    color: #a0a8bd;
}
.nft-liq h5 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.nft-liq h4 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.nft-liq h6 {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.nft-liq p {
    font-size: 11px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}
/* .nft-drop {
    width: 100%;
}
.nft-liq {
    width: 100%;
} */
.nft-drop h3 {
    font-size: 20px;
    font-weight: 600;
}
.nft-drop p {
    font-size: 16px;
    font-weight: 400;
    color: #cacfdb;
}

.detail-container a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
}
.detail-image-box {
    position: relative;
}
.nft-detail-img {
    width :100%;
    height: 0;
    padding-bottom: 110%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    /* top: 0;
    left: 0; */
    z-index: 100;
}
.circle {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #2CCEF4;
    width: 95%;
    z-index: 0;
}
.circle img {
    width: 100%;
    height: 100%;
}
.pos-1 {
    top: -36px;
    left: 20px;
}
.pos-2 {
    top: 23px;
    left: -28px;
}
.nft-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 500;
    background-color: #112455;
}
.box-padding {
    padding-left: 19px;
    padding-right: 19px;
    margin-bottom: 71px !important;
}
.font-mont {
    font-family: 'Montserrat', sans-serif;
}
.border-box {
    display: flex;
    justify-content: space-between;
    border: 1px solid #2CCEF4;
    border-radius: 12px;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
}
.general-info {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
}
.apy-info {
    padding-left: 36px;
    border-left: 1px solid #2CCEF4;
}
.id-box {
    width: 175px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
}
.id-box span {
    background-color: #17E7D6;
    padding: 2px 13px;
    font-size: 20px;
    font-weight: bold;
    color: #112455;
    border-radius: 8px;
}
.id-box + p {
    font-size: 11px;
}
.liqlogo-box {
    width: 30px;
    height: 30px;
    margin-right: 12px;
}
.process-bar {
    display: flex;
    align-items: center;
}
.process-bar hr {
    border: 1px solid #17E7D6;
    flex: 1;
}
.circle-badge {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.active-badge {
    background-color: #17E7D6;
    color: #112455;
}
.inactive-badge {
    background-color: white;
    color: #9AA6CF;
    border: 1px solid #17E7D6;
}
.active-label {
    font-size: 16px;
    color: #112455;
    font-weight: bold;
    margin-top: 11px;
    font-family: 'Montserrat', sans-serif;
}
.inactive-label {
    font-size: 16px;
    color: #9AA6CF;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-top: 11px;
}
.mint-logo-icon {
    width: 40px;
    height: 40px;
}
.mint-logo-label {
    font-size: 24px;
}
.balance-price {
    font-size: 12px;
    color: #9AA6CF; 
    font-weight: 500;
}
.text-primary {
    color: #17E7D6;
}
.text-secondary {
    color: #EABC4E;
}
.name-box {
    display: flex;
    align-items: center;
}
.new-badge {
    background-color: #17E7D6;
    color: #112455;
    border-radius: 50px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    padding: 4px 8px;
    margin-left: 12px;
    font-weight: bold;
}
.nft-heading {
    margin-bottom: 0;
}
.selectmenu {
    border-radius: 12px;
    width: 280px;
    height: 50px;
    border: 2px solid #17E7D6;
    color: #112455;
    background-color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 0 14px;
    cursor: pointer;
}
.selectmenu img {
    width: 34px;
    height: 34px;
}
.selectmenu input {
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #112455;
    font-family: 'Montserrat', sans-serif;
}
.selectmenu input::placeholder {
    color: #e5e5e5;
}
.primary {
    color: #112455;
}
.gray {
    color: #7A8291;
}
.light-gray {
    color: #A8AEB7;
}
.dropmenuMedium {
    width: 110px;
    height: 34px;
    color: white;
    font-size: 12px;
    padding: 0 14px;
    cursor: pointer;
}
.dropmenuMedium img {
    width: 20px;
    height: 20px;
}
.dropmenu {
    border-radius: 100px;
    width: 130px;
    height: 36px;
    border: 2px solid #17E7D6;
    color: white;
    font-size: 14px;
    padding: 0 14px;
    cursor: pointer;
}
.dropmenu img {
    width: 24px;
    height: 24px;
}
.droplist {
    width: 138px;
    position: absolute;
    top: 48px;
    left: 0;
    border-radius: 6px;
    padding: 4px;
    z-index: 300;
    color: white;
    background-color: #131517;
}
.droplist img {
    width: 24px;
    height: 24px;
}
.dropitem {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.dropitem:hover {
    background-color: #373737;
}
.chart {
    width: 250px;
    height: 250px;
}
.white-droplist {
    width: 138px;
    position: absolute;
    top: 56px;
    left: 0;
    border-radius: 6px;
    padding: 4px;
    z-index: 300;
    color: #112455;
    background-color: white;
    box-shadow: 4px 4px 21px #7A8291;
}
.white-droplist img {
    width: 24px;
    height: 24px;
}
.white-dropitem {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.white-dropitem:hover {
    background-color: #e5e5e5;
}
.bridge {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bridge-btn {
    flex: 1;
    margin: 0 6px;
    padding: 15px 30px;
    border-radius: 50px;
    border: 1px solid #112455;
    font-weight: 600;
    color: #17E7D6;
    cursor: pointer;
}
.bridge-body {
    color: #112455;
    background-image: url(../images/background-shape.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bridge-body a {
    color: #112455;
    font-weight: 400;
}
.bridge-body h4 {
    font-size: 16px;
    font-weight: 700;
}
.bridge-body h2 {
    font-size: 20px;
    font-weight: 600;
}
.bridge-body h3 {
    font-size: 16px;
    font-weight: 600;
}
.bridge-body h5 {
    font-size: 14px;
    font-weight: 600;
}
.bridge-body p {
    font-size: 12px;
    font-weight: 500;
}
.bridge-body h6 {
    font-size: 12px;
    font-weight: 400;
}
.bridge-icon {
    width: 34px;
    height: 34px;
}
.bridge-wrapper {
    max-width: 400px !important;
}
.audited-by {
    padding: 0 20px 40px 20px;
}
.audited-by p {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #9AA6CF;
}
.audited-by img {
    width: 50px;
    height: 50px;
    margin: 6px 0 0 -4px;
}
.warning-icon {
    width: 30px;
    height: 30px;
}
.multichain h6 {
    color: #9AA6CF;
    margin-right: 12px !important;
}
.txhash {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    word-break: break-word;
}
.cm-header {
    padding: 20px;
    display: flex;
    justify-content: center;
}
.cm-header img {
    width: 44px;
    height: 44px;
}
.cm-close {
    position: absolute;
    top: 16px;
    right: 16px;
}
.cm-body p {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding: 23px 0;
    text-align: center;
}
.cm-body img {
    width: 178px;
    height: 178px;
}
.cm-body h2 {
    font-size: 30px;
    color: #9AA6CF;
    padding: 42px 0 6px;
    text-align: center;
}
.cm-body h2 span {
    color: #112455;
}
.cm-body h5 {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 41px;
}
.pot-banner {
    width: 100%;
    object-fit: cover;
}

@media only screen and (min-width: 600px) {
    .desktop {display: block}
    .mobile {display: none}
    .bridge {
        justify-content: right;
    }
    .bridge-btn {
        flex: none;
        color: #112455;
    }
    .bg-polygon {
        display: block;
        position: fixed;
        top: -400px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* transform: translate(-50%, 0); */
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-image: url(../images/header_background.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        animation:spin 100s linear infinite;
    }
    @keyframes spin{
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .main-wrapper {
        position: absolute;
        top: 211px;
        left: 50%;
        transform: translateX(-50%);
        /* background-color: #112455; */
        z-index: 2;
    }
    /* Pot section */
    .pot-container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }
    .pot-logo {
        width: 100%;
    }
    .pot-container h6 {
        color: #112455;
        font-size: 24px;
        line-height: 29px;
        font-weight: 600;
    }
    .audited-by {
        padding: 0 60px 40px 60px;
    }
    .audited-by p {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        color: #9AA6CF;
    }
    .audited-by img {
        width: 70px;
        height: 70px;
        margin: 6px 0 0 -8px;
    }
    .logo {
        width: 127px;
    }
    .header {
        position: absolute;
        padding: 35px 30px;
    }
    .content {
        margin-top: 0px;
    }
    .heading-box {
        padding: 32px 60px 24px 60px;
    }
    .new-badge {
        font-size: 14px;
    }
    .heading {
        font-size: 30px;
        color: #112455;
        line-height: 36px;
        margin-bottom: 0;
    }
    .sub-heading {
        font-size: 21px;
        color: #9AA6CF;
        line-height: 25px;
    }
    .coin-logo {
        width: 60px;
        height: 60px;
    }
    .chain-container {
        padding: 0 60px 0;
    }
    .chain-wrapper {
        border-radius: 20px;
        padding: 30px 20px;
        margin-bottom: 20px 0;
    }
    .title-wrapper {
        margin-bottom: 0;
    }
    .title {
        font-size: 21px;
        line-height: 25px;
    }
    .title + h6 {
        padding-left: 30px;
        padding-top: 6px;
    }
    .nft-icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
    }
    .nft-icon + p {
        font-size: 16px;
    }
    .coin-label {
        font-size: 14px;
        line-height: 17px;
    }
    .coin-value {
        font-size: 18px;
        line-height: 22px;
    }
    .logo-wrapper-2 {
        margin-left: -20px!important;
    }
    .balance-price {
        font-size: 14px;
        color: #9AA6CF; 
        font-weight: 500;
    }
    .detail-box {
        padding: 13px 15px;
    }
    .detail-info {
        padding-top: 0;
    }
    .detail-wrapper {
        padding-right: 21px;
    }
    .detail-last {
        padding-left: 21px;
    }
    .blank-block {
        padding-bottom: 29px;
    }
    .btn-wrapper {
        padding-right: 12px!important;
    }
    .footer {
        padding: 70px 0;
    }
    .logo-wrapper {
        padding: 30px;
    }
    .social-wrapper {
        padding: 30px;
    }
    .f-menu-wrapper {
        justify-content: flex-end;
    }
    .f-menu-item {
        font-size: 16px;
        padding: 18px;
        margin: 0;
    }
    .mint-logo-label {
        font-size: 28px;
    }
    .mint-logo-icon {
        width: 50px;
        height: 50px;
    }
    .card-header {
        padding: 38px 22px 28px 57px;
    }
    .card-header h4 {
        font-size: 30px;
        line-height: 36px;
    }
    .card-body {
        padding: 45px 52px;
    }
    .stake-box {
        padding: 29px 37px 36px;
        border-radius: 18px;
    }
    .stake-label {
        font-size: 21px;
        line-height: 25px;
    }
    .stake-value {
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
        flex: 1;
    }
    .stake-form {
        width: 100%;
        font-size: 21px;
        line-height: 27px;
        border: none;
        outline: none;
        /* font-weight: 400; */
    }
    .button-group {
        padding-top: 73px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
    .btn-stake {
        font-size: 21px;
        line-height: 25px;
        border-radius: 20px;
        padding: 24px 0;
    }
    .btn-confirm {
        color: #17E7D6;
        background-color: white;
        border: 1px solid #17E7D6;
    }
    .btn-cancel {
        margin-right: 0;
    }
    .card-footer {
        padding-bottom: 38px;
    }
    .card-footer a {
        font-size: 21px;
        line-height: 25px;
        font-weight: 400;
    }
    .card-footer p {
        font-size: 16px;
    }
    .badge {
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
    }
    /* Detail page */
    .detail-header {
        padding: 35px 62px;
    }
    .box-padding {
        padding-left: 79px;
        padding-right: 79px;
        margin-bottom: 125px !important;
    }
    .nft-detail h1 {
        font-size: 65px;
        line-height: 77px;
    }
    .nft-detail h3 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: #cacfdb;
        margin-bottom: 7px !important;
    }
    .nft-detail h2 {
        font-size: 30px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 17px !important;
    }
    .nft-detail h4 {
        font-size: 30px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 7px !important;
    }
    .nft-detail h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
    }
    .nft-detail h6 {
        font-size: 12px;
        font-weight: 400;
    }
    .grid-box {
        display: block;
    }


    .detail-container a {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
    }
    .btn-round-lg {
        background-color: #17E7D6;
        font-size: 20px;
        font-weight: 500;
        color: #112455;
        font-family: 'Montserrat', sans-serif;
        border: 1px solid #17E7D6;
        border-radius: 50px;
        width: 224px;
        height: 73px;
        cursor: pointer;
    }
    .btn-border-lg {
        background-color: #112455;
        font-size: 20px;
        font-weight: 500;
        color: #17E7D6;
        font-family: 'Montserrat', sans-serif;
        border: 1px solid #17E7D6;
        border-radius: 50px;
        width: 224px;
        height: 73px;
        cursor: pointer;
    }
    .nft-heading {
        margin-bottom: 36px!important;
    }
    .done {
        height: 74px;
        font-size: 21px;
    }
    .deposit-success p {
        font-size: 21px;
        line-height: 22px;
    }
}
@media only screen and (min-width: 800px) {
    .bg-polygon {
        top: -500px;
    }
    .box-padding {
        padding-left: 129px;
        padding-right: 129px;
        margin-bottom: 125px !important;
    }
    .grid-box {
        display: block;
    }
}
@media only screen and (min-width: 940px) {
    .bg-polygon {
        top: -600px;
    }
    .box-padding {
        padding-left: 29px;
        padding-right: 29px;
        margin-bottom: 125px !important;
    }
    .grid-box {
        display: flex;
        flex-direction: row-reverse;
    }
    .nft-drop {
        width: 50%;
    }
    .nft-liq {
        width: 50%;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}